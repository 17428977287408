import { useSearchParams } from "@solidjs/router";
import { LoginScreen } from "@core/screens/LoginScreen";

const LogIn = () => {
  const [params] = useSearchParams();

  return (
    <LoginScreen
      invite={
        params.invite
          ? { type: "direct", token: params.invite as string }
          : params.collectionLink
            ? { type: "link", token: params.collectionLink as string }
            : undefined
      }
    />
  );
};

export default LogIn;
