import { StorytellLogo } from "@core/components/StorytellLogo";
import { classNames } from "@core/lib/classNames";
import { MarketingCTA } from "@core/components/cta/MarketingCTA";
import { TbSparkles } from "solid-icons/tb";
import { OrDivider } from "@core/components/_original/OrDivider";
import { A } from "@solidjs/router";
import { Tooltip, TooltipTrigger } from "@core/components/_original/Tooltip";
import { MarketingGradientBlob } from "@core/marketing/components/MarketingGradientBlob";
import { Show, type Component } from "solid-js";
import { MagicLinkSentScreen } from "./MagicLinkSentScreen";
import styles from "./LoginFormScreen.module.css";
import { StBrandGoogle } from "@core/components/icons";

/**
 * Extracted the signup card UI into a separate component in preparation
 * for gating features behind auth modals that will reuse this.
 */
export const LoginFormScreen: Component<{
  onMagicLink: (email: string) => void;
  onGoogleSignup: () => void;
  onSignup?: () => void;
  email: string;
  status: "idle" | "loading" | "sent";
  invite: { type: "direct" | "link"; token: string } | undefined;
  setEmail: (email: string) => void;
}> = (props) => {
  const title = () => {
    if (props.invite) {
      return "Log in and explore the collection you've been invited to.";
    }
    return "Log in to Storytell";
  };

  return (
    <Show when={props.status !== "sent"} fallback={<MagicLinkSentScreen />}>
      <div class={classNames("dark", styles["login"])}>
        <div class="w-screen pointer-events-none opacity-0" aria-hidden />
        <MarketingGradientBlob />
        <div class="w-full">
          <StorytellLogo />
        </div>

        <div class={styles["login__card"]}>
          <p class={styles["login__subtitle"]}>{title()}</p>

          <div class={styles["login__options"]}>
            <form
              class={styles["login__form"]}
              onSubmit={(e) => {
                e.preventDefault();
                props.onMagicLink(e.currentTarget.email.value);
              }}
            >
              <input
                value={props.email}
                onInput={(e) => props.setEmail(e.currentTarget.value)}
                required
                type="email"
                name="email"
                id="email"
                placeholder="your@email.com"
                class={styles["login__form-input"]}
              />

              <Tooltip
                theme="light"
                placement="bottom"
                content={
                  <p>
                    Enter your email, and we’ll send you a secure link.
                    <br /> Click it to log in–no password needed.
                  </p>
                }
              >
                <TooltipTrigger as="div">
                  <MarketingCTA
                    disabled={props.status === "loading" || props.status === "sent"}
                    type="submit"
                    icon={TbSparkles}
                    compact
                    centered
                    iconPosition="left"
                    square
                    class="!w-full"
                  >
                    Email me a Magic Link
                  </MarketingCTA>
                </TooltipTrigger>
              </Tooltip>
            </form>
            <OrDivider />

            <MarketingCTA
              icon={StBrandGoogle}
              disabled={props.status === "loading" || props.status === "sent"}
              compact
              centered
              iconPosition="left"
              square
              dark
              class={styles["login__google"]}
              onClick={props.onGoogleSignup}
            >
              Log in with Google
            </MarketingCTA>

            <A
              class={styles["login__login-link"]}
              onClick={props.onSignup}
              href={props.onSignup ? "#" : "/auth/signup"}
            >
              Don't have an account?
            </A>
          </div>
        </div>
      </div>
    </Show>
  );
};
